// Fonts
// @import url("https://fonts.googleapis.com/css?family=Nunito");
// Variables
@import "variables";
@import "bootstrap-icons";
// Bootstrap
@import "~bootstrap/scss/bootstrap";

body {
    min-height: 100vh;
}

#app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#app-main {
    display: flex;
    flex: 1;
}

#main-container {
    margin-top: 40px;
}

label {
    width: 100%;
}

.table tr{
    &.disabled{
        opacity: 0.5;
    }
}

.table th,
.table td {
    vertical-align: inherit;
}

th.sortable {
    cursor: pointer;
}

li.page-item {
    cursor: pointer;
    &.disabled {
        cursor: auto;
    }
}

button.disabled {
    cursor: default;
}

.color-example {
    display: inline-block;
    width: 40px;
    height: 20px;
    vertical-align: text-bottom;
}

.classify-radios > input[type="radio"] {
    visibility: hidden;
    + label {
        width: 80px;
        cursor: pointer;
        text-align: center;
        margin: 0;
    }
    &:checked + label {
        font-weight: bold;
    }
}

.wysiwyg-field {
    height: auto;
    min-height: 157px;
}

.api-token-container {
    padding: 5px;
    border: 1px solid gray;
    overflow-x: auto;
}

.radio-row {
    &.radio-row-header {
        .radio-row-item {
            font-weight: bold;
        }
    }
    .radio-row-item {
        display: inline-block;
        width: 15%;
        text-align: center;
        &.radio-row-label {
            width: 37%;
            text-align: left;
        }
    }
    label {
        cursor: pointer;
        margin: 0;
    }
}

.add-caret {
    &::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0;
        border-right: 0.3em solid transparent;
        border-bottom: 0.3em solid;
        border-left: 0.3em solid transparent;
        transform: rotate(180deg);
        transition: transform $default-transition;
    }
    &.caret-up::after {
        transform: rotate(0deg);
    }
    &.caret-left::after {
        transform: rotate(270deg);
    }
    &.caret-right::after {
        transform: rotate(90deg);
    }
}

.fade-in {
    opacity: 1;
    transition: opacity $slow-transition;
}
.fade-out-half {
    opacity: 0.3;
    transition: opacity $slow-transition;
}
.fade-out {
    opacity: 0;
    transition: opacity $slow-transition;
}

.card-header h4,
.card-header h5 {
    margin-bottom: 0px;
}

.table th {
    border-top: none;
}

.navbar-nav .nav-link {
    white-space: nowrap;
}

.teov2-logo {
    width: 100px;
    transform: translateY(-2px);
}

.welcome-image {
    width: 200px;
    margin-top: 40px;
    transform: rotate(-2deg);
}

.welcome-container {
    // display: flex;
    position: relative;
    .welcome-row {
        flex: 1;
    }
    h5 {
        text-transform: uppercase;
        color: #2b67ac;
    }
    .version-nr {
        margin-top: 20px;
    }
    .support-info,
    .app-link {
        margin-top: 60px;
    }
    .tcl-logo {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 80px;
    }
}

#pw-link-container {
    border: 1px solid black;
    white-space: nowrap;
    overflow-x: scroll;
}

#study-test {
    label {
        width: 100%;
    }
    > .input-group {
        padding: 40px;
        border: 1px solid grey;
        select {
            // width: calc(100% - 40px);
        }
        > div {
            width: 100%;
        }
    }
}
.study-preset,
#study-options-json {
    visibility: hidden;
    height: 0px;
}
#study-json-string {
    visibility: hidden;
    height: 0px;
    width: 0px;
}
.study-set-all-button {
}

.study-form-container {
    .dashboard-dataset-form {
        position: relative;
        padding: 5px 0;
        border-top: 1px solid lightgray;
        > div,
        > label {
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
        > button {
            margin: 25px 15px 0;
            width: 40px;
            height: 40px;
        }
    }
}

#statistics-general-inputs {
    width: unset;
}

#statistics-show-form {
    display: inline-block;
    float: right;
    textarea[name="json_request"],
    textarea[name="selections-json"] {
        display: none;
    }
}

input.statistic-session-value-checkbox {
    margin-top: 0.36rem;
}

#statistic-text-session-data {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}
